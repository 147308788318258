<template>
  <b-modal
    id="modalCreateEditCentroCusto"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="col-lg-12">
        <b-tabs
          justified
          nav-class="nav-tabs-custom"
          content-class="p-3 text-muted"
        >
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Dados</span>
            </template>

            <!-- NOME | TIPO DE CLIENTE | DATA DE INICIO -->
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Descrição: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.centroCusto.descricao.$error }"
                  placeholder="Descrição do Centro de Custo"
                  v-model="$v.centroCusto.descricao.$model"
                />
                <span
                  v-if="!$v.centroCusto.descricao.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="!$v.centroCusto.descricao.minLength"
                  class="invalid-feedback"
                >
                  O nome deve possuir pelo menos 3 caracteres
                </span>
              </div>
            </div>
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Verbas</span>
            </template>
            <div>
              <button
                type="button"
                @click="addVerba()"
                class="btn btn-primary"
                title="Adicionar rasgadinha"
              >
                <i class="bx ri-add-line"></i> Nova verba
              </button>
            </div>
            <div
              v-for="(verba, index) in verbas"
              :key="index"
              class="card task-box"
            >
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>Ano: <span class="text-danger">*</span></label>
                  <input
                    type="year"
                    class="form-control"
                    placeholder="Ano"
                    :name="'verbas[' + index + '].[ano]'"
                    :id="'verbas[' + index + ']'"
                    v-model="verbas[index].ano"
                  />
                </div>
                <div class="form-group col-sm-4">
                  <label>Valor:</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Valor"
                    :name="'verbas[' + index + '].[valor_total]'"
                    :id="'verbas[' + index + ']'"
                    v-model="verbas[index].valor_total"
                  />
                </div>
                <div class="form-group col-sm-1">
                  <br />
                  <button
                    type="button"
                    @click="deleteVerba(index)"
                    class="btn btn-lg btn-outline-danger"
                    title="Adicionar item"
                  >
                    <i class="bx ri-delete-bin-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditCentroCustoModal',

  props: {
    centroCustoId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      centroCusto: {
        descricao: '',
      },
      verbas: [],
      disableButton: false,
      loadDataEditing: false,
    }
  },

  computed: {
    textModal() {
      return this.centroCustoId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    addVerba() {
      this.verbas.push({
        ano: '',
        valor_total: '',
      })
    },
    deleteVerba(index) {
      this.verbas.splice(index, 1)
    },

    async getCentroCusto() {
      try {
        this.loadDataEditing = true
        this.centroCusto = await this.$store.dispatch(
          'getCentroCusto',
          this.centroCustoId,
        )
        this.verbas = await this.$store.dispatch(
          'getVerbasByCentroCusto',
          this.centroCustoId,
        )
        this.$bvModal.show('modalCreateEditCentroCusto')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.centroCustoId) {
            response = await this.$store.dispatch('updateCentroCusto', {
              id: this.centroCustoId,
              centroCusto: this.centroCusto,
              verbas: this.verbas,
            })
          } else {
            response = await this.$store.dispatch('saveCentroCusto', {
              centroCusto: this.centroCusto,
              verbas: this.verbas,
            })
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    centroCusto: {
      descricao: {
        required,
        minLength: minLength(3),
      },
    },
  },

  mounted() {
    if (this.centroCustoId) {
      this.getCentroCusto()
    } else {
      this.$bvModal.show('modalCreateEditCentroCusto')
    }
  },
}
</script>
