<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditContaModal
      v-if="showCreateEditContaModal"
      :contaId="contaIdSelected"
      @closeModal="closeCreateEditContaModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Contas</h5>
        <div v-if="getModuleUserByCode('1.01.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Nova Conta"
            @click="createEditConta()"
          >
            <i class="ri-add-fill align-middle"></i>Nova Conta
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListContas :contas="contas" @editConta="createEditConta" />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListContas from '@/components/financeiro/contas/ListContas.vue'
import CreateEditContaModal from '@/components/financeiro/contas/CreateEditContaModal.vue'

export default {
  name: 'Contas',

  components: {
    Layout,
    PageHeader,
    CreateEditContaModal,
    LoadingData,
    ListContas,
  },

  data() {
    return {
      title: 'Contas',
      items: [
        {
          text: 'Contas',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditContaModal: false,
      contas: null,
      contaIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getContas() {
      try {
        this.contas = await this.$store.dispatch('getContas')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditConta(contaId) {
      this.contaIdSelected = contaId || null
      this.showCreateEditContaModal = true
    },

    closeCreateEditContaModal(loadListContas) {
      if (loadListContas) {
        this.getContas()
      }
      this.showCreateEditContaModal = false
    },
  },

  mounted() {
    this.getContas()
  },
}
</script>
