<template>
  <div>
    <!-- HTML -->
    <div class="offcanvas" v-bind:class="{ show: showPanel }" v-if="showPanel">
      <!-- conteúdo do painel aqui -->
      <div class="card">
        <button @click="closePanel" class="btn text-right">
          <i class="ri-close-line"></i>
        </button>
        <div class="card-header bg-transparent border-bottom">
          <h3>{{ lancamento.id }} - {{ lancamento.titulo }}</h3>
          <h6>{{ lancamento.cliente_nome }}</h6>
          <br />
          <h5 class="card-title mb-0">Detalhes do Lançamento</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <p>
                <strong>Tipo:</strong><br />
                <span v-html="lancamento.tipo"></span>
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <strong>Forma de Pagamento:</strong><br />
                {{ lancamento.forma_pagamento_descricao }}
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <strong>Documento:</strong><br />
                {{ lancamento.num_documento }}
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <strong>Conta:</strong><br />
                {{ lancamento.conta_descricao }}
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <strong>Subcategoria:</strong><br />
                {{ lancamento.subcategoria_contas_descricao }}
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <strong>Filial:</strong><br />
                {{ lancamento.unidade_descricao }}
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <strong>Data vencimento:</strong><br />
                {{ lancamento.data_vencimento }}
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <strong>Competência:</strong> <br />{{ lancamento.competencia }}
              </p>
            </div>
            <div v-if="lancamento.data_pagamento" class="col-sm-6">
              <p>
                <strong>Data Pagamento:</strong> <br />{{
                  lancamento.data_pagamento
                }}
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <strong>Nota Fiscal:</strong> <br />{{ lancamento.nota_fiscal }}
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <strong>Valor:</strong><br />
                <span v-html="lancamento.valor_total"></span>
              </p>
              <br />
            </div>
            <div class="col-sm-12">
              <p><strong>Obs.:</strong> {{ lancamento.observacao }}</p>
              <br />
            </div>
          </div>
          <div class="row">
            <div
              v-if="lancamento.tipoId == 1 || lancamento.tipoId == 2"
              class="col-sm-6"
            >
              <div>
                <button
                  v-if="getModuleUserByCode('2.01.003')"
                  type="button"
                  class="btn btn-primary ml-auto"
                  title="Baixar Lançamento"
                  @click="baixaPagamentoConta(lancamento.id)"
                >
                  Baixar Lançamento
                </button>
              </div>
            </div>
            <div class="col-sm-6">
              <router-link
                v-if="getModuleUserByCode('2.01.003')"
                :to="{
                  name: 'CreateLancamento',
                  params: { lancamentoId: lancamento.id.toString() },
                }"
                class="btn btn-primary ml-auto"
                title="Novo Lançamento"
              >
                Editar
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BaixaPagamentoModal
      v-if="showBaixaPagamentoModal"
      :lancamentoId="lancamentoIdSelected"
      @closeModal="closeBaixaPagamentoModal"
    />
    <br />
    <div class="card mx-auto">
      <b-tabs
        justified
        nav-class="nav-tabs-custom mt-3 mb-3"
        content-class="text-muted"
      >
        <b-tab active @click="getLancamentos(['1', '2'])">
          <template v-slot:title>
            <span class="d-inline-block d-sm-none"> A pagar e A Receber </span>
            <h6 class="d-none d-sm-inline-block text-bold">
              A pagar e A Receber
            </h6>
          </template>
          <div class="card mt-3 mb-3 mr-3 ml-3 p-3">
            <div class="form-row justify-content-end form-inline">
              <div class="form-group mr-3" style="width: 200px">
                <multiselect
                  v-model="contasSelecionadas"
                  :options="contas"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Selecione as contas"
                  label="descricao"
                  track-by="descricao"
                  :preselect-first="true"
                  :selectLabel="'Selecionado'"
                >
                  <template
                    v-if="contasSelecionadas.length > 0"
                    slot="selection"
                    >{{ contasSelecionadas.length }} contas
                    selecionadas</template
                  >
                </multiselect>
              </div>
              <div class="form-group mr-3">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data Inicial"
                  v-model="dataInicio"
                />
              </div>
              <div class="form-group mr-3">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data Final"
                  v-model="dataFinal"
                />
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-primary"
                  title="Buscar"
                  @click="getLancamentos(['1', '2'])"
                >
                  <span>Buscar</span>
                </button>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab @click="getLancamentos(['2'])">
          <template v-slot:title>
            <span class="d-inline-block d-sm-none"> Contas a pagar </span>
            <h6 class="d-none d-sm-inline-block">Contas a pagar</h6>
          </template>
          <div class="card mt-3 mb-3 mr-3 ml-3 p-3">
            <div class="form-row justify-content-end form-inline">
              <div class="form-group mr-3" style="width: 200px">
                <multiselect
                  v-model="contasSelecionadas"
                  :options="contas"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Selecione as contas"
                  label="descricao"
                  track-by="descricao"
                  :preselect-first="true"
                  :selectLabel="'Selecionado'"
                >
                  <template
                    v-if="contasSelecionadas.length > 0"
                    slot="selection"
                    >{{ contasSelecionadas.length }} contas
                    selecionadas</template
                  >
                </multiselect>
              </div>
              <div class="form-group mr-3">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data Inicial"
                  v-model="dataInicio"
                />
              </div>
              <div class="form-group mr-3">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data Final"
                  v-model="dataFinal"
                />
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-primary"
                  title="Buscar"
                  @click="getLancamentos(['2'])"
                >
                  <span>Buscar</span>
                </button>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab @click="getLancamentos(['1'])">
          <template v-slot:title>
            <span class="d-inline-block d-sm-none"> Conta a receber </span>
            <h6 class="d-none d-sm-inline-block">Conta a receber</h6>
          </template>
          <div class="card mt-3 mb-3 mr-3 ml-3 p-3">
            <div class="form-row justify-content-end form-inline">
              <div class="form-group mr-3" style="width: 200px">
                <multiselect
                  v-model="contasSelecionadas"
                  :options="contas"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Selecione as contas"
                  label="descricao"
                  track-by="descricao"
                  :preselect-first="true"
                  :selectLabel="'Selecionado'"
                >
                  <template
                    v-if="contasSelecionadas.length > 0"
                    slot="selection"
                    >{{ contasSelecionadas.length }} contas
                    selecionadas</template
                  >
                </multiselect>
              </div>
              <div class="form-group mr-3">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data Inicial"
                  v-model="dataInicio"
                />
              </div>
              <div class="form-group mr-3">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data Final"
                  v-model="dataFinal"
                />
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-primary"
                  title="Buscar"
                  @click="getLancamentos(['1'])"
                >
                  <span>Buscar</span>
                </button>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab @click="getLancamentos(['3', '4'])">
          <template v-slot:title>
            <span class="d-inline-block d-sm-none"> Entradas e Saídas </span>
            <h6 class="d-none d-sm-inline-block">Entradas e Saídas</h6>
          </template>
          <div class="card mt-3 mb-3 mr-3 ml-3 p-3">
            <div class="form-row justify-content-end form-inline">
              <div class="form-group mr-3" style="width: 200px">
                <multiselect
                  v-model="contasSelecionadas"
                  :options="contas"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Selecione as contas"
                  label="descricao"
                  track-by="descricao"
                  :preselect-first="true"
                  :selectLabel="'Selecionado'"
                >
                  <template
                    v-if="contasSelecionadas.length > 0"
                    slot="selection"
                    >{{ contasSelecionadas.length }} contas
                    selecionadas</template
                  >
                </multiselect>
              </div>
              <div class="form-group mr-3">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data Inicial"
                  v-model="dataInicio"
                />
              </div>
              <div class="form-group mr-3">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data Final"
                  v-model="dataFinal"
                />
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-primary"
                  title="Buscar"
                  @click="getLancamentos(['3', '4'])"
                >
                  <span>Buscar</span>
                </button>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <div v-if="lancamentos" class="card-body">
        <div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Mostrar
                  <b-form-select
                    v-model="table.perPage"
                    size="sm"
                    :options="table.pageOptions"
                  />
                  registros
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  Pesquisar:
                  <b-form-input
                    type="search"
                    class="form-control form-control-sm ml-2"
                    placeholder="Pesquisar..."
                    v-model="table.filter"
                  />
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <b-table
              class="table-striped"
              :items="lancamentos"
              :fields="table.fields"
              responsive="sm"
              :per-page="table.perPage"
              :current-page="table.currentPage"
              :sort-by.sync="table.sortBy"
              :sort-desc.sync="table.sortDesc"
              :filter="table.filter"
              :filter-included-fields="table.filterOn"
              @filtered="onFiltered"
            >
              <template #cell(titulo)="row">
                <span v-html="row.value"></span>
              </template>
              <template #cell(data_vencimento)="row">
                <span v-html="row.value" class="text-center"></span>
              </template>
              <template #cell(conta_descricao)="row">
                <span v-html="row.value"></span>
              </template>
              <template #cell(valor_total)="row">
                <span v-html="row.value"></span>
              </template>
              <template #cell(actions)="row">
                <button
                  v-if="getModuleUserByCode('2.01.003')"
                  type="button"
                  class="btn btn-outline-secondary rounded-pill"
                  title="Editar"
                  @click="openPanel(row.item)"
                >
                  <i class="ri-pencil-line align-middle"></i>
                </button>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col d-inline-flex align-items-center">
              Quantidade de registros: {{ lancamentos.length }}
              <span v-if="table.filter != null && table.filter != ''">
                &nbsp;(Filtrados: {{ table.totalRows }})
              </span>
            </div>
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <b-pagination
                    v-model="table.currentPage"
                    :total-rows="table.totalRows"
                    :per-page="table.perPage"
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- <ListLancamentos
          :lancamentos="lancamentos"
          @baixaPagamento="baixaPagamentoConta"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { valorFormatado, tipoLancamentoTranslate } from '@/utils/strings'
import { formatarDataLancamento, dateSqlToDateBR } from '@/utils/date'
import Multiselect from 'vue-multiselect'
import BaixaPagamentoModal from '@/components/financeiro/lancamentos/BaixaPagamentoModal.vue'
import moment from 'moment'

export default {
  name: 'FiltroLancamentos',

  components: {
    Multiselect,
    BaixaPagamentoModal,
  },

  data() {
    return {
      dataInicio: '',
      dataFinal: '',
      contas: [],
      contasSelecionadas: [],
      loadData: false,
      showCreateEditLancamentoModal: false,
      lancamentos: null,
      lancamentosRows: null,
      lancamentoIdSelected: null,
      showBaixaPagamentoModal: false,
      table: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: false,
        sortDesc: false,
        fields: [
          {
            key: 'titulo',
            label: 'Lançamento',
            formatter: (value, key, item) => {
              return `<h6 class="d-none d-sm-inline-block text-bold">${item.id} - ${item.titulo}</h6> <br> ${item.cliente_nome}`
            },
          },
          {
            key: 'data_vencimento',
            label: 'Data',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            tdClass: 'text-center align-middle',
            thClass: 'text-center align-middle',
            formatter: (value, key, item) => {
              return formatarDataLancamento(item)
            },
          },
          {
            key: 'conta_descricao',
            label: 'Conta',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            tdClass: 'text-center align-middle',
            thClass: 'text-center align-middle',
            formatter: (value, key, item) => {
              return `<h6 class="d-none d-sm-inline-block text-bold"> <i class="ri-bank-fill align-middle"></i> ${item.conta_descricao}</h6>`
            },
          },
          {
            key: 'valor_total',
            label: 'Valor',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            tdClass: 'text-center align-middle',
            thClass: 'text-center align-middle',
            formatter: (value, key, item) => {
              return valorFormatado(item)
            },
          },
          {
            key: 'actions',
            label: 'Ações',
            tdClass: 'text-center align-middle',
            thClass: 'text-center align-middle',
            thStyle: { width: '130px' },
          },
        ],
      },
      showPanel: false,
      lancamento: {
        id: '',
        titulo: '',
        data_vencimento: '',
        competencia: '',
        data_pagamento: '',
        tipoId: '',
        tipo: '',
        cliente_nome: '',
        valor_total: '',
        num_documento: '',
        nota_fiscal: '',
        observacao: '',
        forma_pagamento_descricao: '',
        subcategoria_contas_descricao: '',
        unidade_descricao: '',
        conta_descricao: '',
      },
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    onFiltered(filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    baixarLancamento(lancamentoId) {
      this.$emit('baixaPagamento', lancamentoId)
      this.closePanel()
    },
    openPanel(item) {
      this.lancamento.id = item.id
      this.lancamento.titulo = item.titulo
      this.lancamento.data_vencimento = dateSqlToDateBR(item.data_vencimento)
      this.lancamento.competencia = dateSqlToDateBR(item.competencia)
      this.lancamento.data_pagamento = moment(item.data_pagamento).isValid()
        ? dateSqlToDateBR(item.data_pagamento)
        : null
      this.lancamento.tipoId = item.tipo
      this.lancamento.tipo = tipoLancamentoTranslate(item.tipo)
      this.lancamento.cliente_nome = item.cliente_nome
      this.lancamento.valor_total = valorFormatado(item)
      this.lancamento.num_documento = item.num_documento
      this.lancamento.nota_fiscal = item.nota_fiscal
      this.lancamento.observacao = item.observacao
      this.lancamento.forma_pagamento_descricao = item.forma_pagamento_descricao
      this.lancamento.subcategoria_contas_descricao =
        item.subcategoria_contas_descricao
      this.lancamento.unidade_descricao = item.unidade_descricao
      this.lancamento.conta_descricao = item.conta_descricao
      this.showPanel = true
    },

    closePanel() {
      this.showPanel = false
    },

    async getContas() {
      try {
        this.contas = await this.$store.dispatch('getContas')
        this.contasSelecionadas = this.contas
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getLancamentos(tipoContas) {
      try {
        const contasAux = []
        for (let i = 0; i < this.contasSelecionadas.length; i += 1) {
          contasAux.push(this.contasSelecionadas[i].id)
        }
        this.lancamentos = await this.$store.dispatch('getLancamentos', {
          tipo: tipoContas,
          data_inicial: this.dataInicio,
          data_final: this.dataFinal,
          contas: contasAux,
        })
        this.table.totalRows = this.lancamentos.length
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    addTag(novaConta) {
      const conta = {
        descricao: novaConta,
      }
      this.contasSelecionadas.push(conta)
    },

    baixaPagamentoConta(lancamentoId) {
      this.closePanel()
      this.lancamentoIdSelected = lancamentoId || null
      this.showBaixaPagamentoModal = true
    },

    closeBaixaPagamentoModal(loadListLancamentos) {
      if (loadListLancamentos) {
        this.getLancamentos(['1', '2'])
      }
      this.showBaixaPagamentoModal = false
    },
  },

  async mounted() {
    await this.getContas()
    const currentDate = new Date()
    this.dataFinal = moment(currentDate).format('YYYY-MM-DD')
    this.dataInicio = moment(
      currentDate.setMonth(currentDate.getMonth() - 1),
    ).format('YYYY-MM-DD')
    this.getLancamentos(['1', '2'])
  },
}
</script>

<style>
.offcanvas {
  position: fixed;
  top: 0;
  right: -250px;
  bottom: 0;
  width: 400px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-in-out;
  z-index: 9999;
}

.offcanvas.show {
  right: 0;
}
</style>
