<template>
  <b-modal
    id="modalCreateEditConta"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Descricao: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.conta.descricao.$error }"
            placeholder="Descrição da conta"
            v-model="$v.conta.descricao.$model"
          />
          <span v-if="!$v.conta.descricao.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.conta.descricao.minLength" class="invalid-feedback">
            A descrição deve possuir pelo menos 3 caracteres
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Agência: </label>
          <input
            type="text"
            class="form-control"
            placeholder="0000-0"
            :class="{ 'is-invalid': $v.conta.agencia.$error }"
            v-model="$v.conta.agencia.$model"
          />
          <span v-if="!$v.conta.agencia.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Nº da conta: </label>
          <input
            type="text"
            class="form-control"
            placeholder="0000000-0"
            :class="{ 'is-invalid': $v.conta.numero_conta.$error }"
            v-model="$v.conta.numero_conta.$model"
          />
          <span v-if="!$v.conta.numero_conta.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Banco:</label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.conta.banco_id.$error }"
            v-model="$v.conta.banco_id.$model"
          >
            <option value="">Selecione um banco</option>
            <option
              v-for="banco in bancos"
              :key="banco.id"
              :value="banco.id"
              :title="`${banco.codigo} - ${banco.nome}`"
            >
              {{ banco.nome }}
            </option>
          </select>
          <span v-if="!$v.conta.banco_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Saldo Inicial: <span class="text-danger">*</span></label>
          <div class="input-group">
            <span
              class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
            >
              <span class="input-group-text">R$</span></span
            >
            <input
              type="number"
              class="form-control"
              step="0.01"
              :class="{ 'is-invalid': $v.conta.saldo_inicial.$error }"
              v-model.number="$v.conta.saldo_inicial.$model"
              placeholder="000,00"
              @blur="formatarValor"
            />
          </div>
          <span
            v-if="!$v.conta.saldo_inicial.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <p>Padrão: <span class="text-danger">*</span></p>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="padraoSim"
              value="1"
              v-model="conta.padrao"
            />
            <label class="form-check-label" for="padraoSim"> Sim </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="padraoNao"
              value="0"
              v-model="conta.padrao"
            />
            <label class="form-check-label" for="padraoNao"> Não </label>
          </div>
        </div>
        <div v-if="contaId" class="form-group col-sm-6">
          <p>Status: <span class="text-danger">*</span></p>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusInativo"
              value="0"
              v-model="conta.status"
            />
            <label class="form-check-label" for="statusInativo">
              Inativo
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusAtivo"
              value="1"
              v-model="conta.status"
            />
            <label class="form-check-label" for="statusAtivo"> Ativo </label>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditContaModal',
  props: {
    contaId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      conta: {
        descricao: '',
        agencia: '',
        numero_conta: '',
        banco_id: '',
        padrao: 0,
        saldo_inicial: '',
      },
      bancos: '',
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.contaId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async formatarValor() {
      const novoValor = this.conta.saldo_inicial.toFixed(2)
      this.conta.saldo_inicial = novoValor
    },
    async getConta() {
      try {
        this.conta = await this.$store.dispatch('getConta', this.contaId)
        this.$bvModal.show('modalCreateEditConta')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getBancos() {
      try {
        this.bancos = await this.$store.dispatch('getBancos')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.contaId) {
            response = await this.$store.dispatch('updateConta', {
              id: this.contaId,
              conta: this.conta,
            })
          } else {
            response = await this.$store.dispatch('saveConta', this.conta)
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    conta: {
      descricao: {
        required,
        minLength: minLength(3),
      },
      agencia: {
        required,
      },
      numero_conta: {
        required,
      },
      banco_id: {
        required,
      },
      saldo_inicial: {
        required,
      },
    },
  },

  mounted() {
    this.getBancos()
    if (this.contaId) {
      this.getConta()
    } else {
      this.$bvModal.show('modalCreateEditConta')
    }
  },
}
</script>
