<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card">
      <div class="card-body">
        <CreateEditLancamentoComponent :lancamentoId="lancamentoId" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import CreateEditLancamentoComponent from '@/components/financeiro/lancamentos/CreateEditLancamentoComponent.vue'

export default {
  name: 'CreateEditLancamento',

  components: {
    Layout,
    PageHeader,
    CreateEditLancamentoComponent,
  },

  data() {
    return {
      title: 'Novo Lançamento',
      lancamentoId: this.$route.params.lancamentoId,
      items: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Lançamentos',
        },
        {
          text: 'Novo Lançamento',
          active: true,
        },
      ],
    }
  },
}
</script>
