<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Mostrar
            <b-form-select
              v-model="table.perPage"
              size="sm"
              :options="table.pageOptions"
            />
            registros
          </label>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Pesquisar:
            <b-form-input
              type="search"
              class="form-control form-control-sm ml-2"
              placeholder="Pesquisar..."
              v-model="table.filter"
            />
          </label>
        </div>
      </div>
    </div>
    <div class="table-responsive mb-0">
      <b-table
        :items="transferencias"
        :fields="table.fields"
        responsive="sm"
        :per-page="table.perPage"
        :current-page="table.currentPage"
        :sort-by.sync="table.sortBy"
        :sort-desc.sync="table.sortDesc"
        :filter="table.filter"
        :filter-included-fields="table.filterOn"
        @filtered="onFiltered"
      >
        <template #cell(data)="row">
          <span v-html="row.value"></span>
        </template>
        <template #cell(valor)="row">
          <span v-html="row.value"></span>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col d-inline-flex align-items-center">
        Quantidade de registros: {{ transferencias.length }}
        <span v-if="table.filter != null && table.filter != ''">
          &nbsp;(Filtrados: {{ table.totalRows }})
        </span>
      </div>
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination
              v-model="table.currentPage"
              :total-rows="table.totalRows"
              :per-page="table.perPage"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { dateTimeSqlToDateBR } from '@/utils/date'
import { moneyToCurrencyBr } from '@/utils/strings'

export default {
  name: 'ListTransferencias',

  props: {
    transferencias: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      table: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: 'data',
        sortDesc: true,
        fields: [
          { key: 'motivo', label: 'Transferência', sortable: true },
          {
            key: 'data',
            label: 'Data',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value) => {
              return dateTimeSqlToDateBR(value)
            },
          },
          { key: 'createdBy', label: 'Responsável', sortable: true },
          { key: 'conta_origem', label: 'Origem', sortable: true },
          { key: 'conta_destino', label: 'Destino', sortable: true },
          {
            key: 'valor',
            label: 'Valor',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value) => {
              return moneyToCurrencyBr(value)
            },
          },
        ],
      },
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    onFiltered(filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
  },

  mounted() {
    this.table.totalRows = this.transferencias.length
  },
}
</script>
