<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditFormaPagamentoModal
      v-if="showCreateEditFormaPagamentoModal"
      :formaPagamentoId="formaPagamentoIdSelected"
      @closeModal="closeCreateEditFormaPagamentoModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Formas de Pagamentos</h5>
        <div v-if="getModuleUserByCode('2.05.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Nova forma de pagamento"
            @click="createEditFormaPagamento()"
          >
            <i class="ri-add-fill align-middle"></i>Nova forma de pagamento
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListFormasPagamentos
          :formasPagamentos="formasPagamentos"
          @editFormaPagamento="createEditFormaPagamento"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListFormasPagamentos from '@/components/financeiro/formaPagamento/ListFormasPagamentos.vue'
import CreateEditFormaPagamentoModal from '@/components/financeiro/formaPagamento/CreateEditFormaPagamentoModal.vue'

export default {
  name: 'FormaPagamento',

  components: {
    Layout,
    PageHeader,
    CreateEditFormaPagamentoModal,
    LoadingData,
    ListFormasPagamentos,
  },

  data() {
    return {
      title: 'Formas de Pagamento',
      items: [
        {
          text: 'Formas de Pagamento',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditFormaPagamentoModal: false,
      formasPagamentos: null,
      formaPagamentoIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getFormasPagamentos() {
      try {
        this.formasPagamentos = await this.$store.dispatch(
          'getFormasPagamentos',
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditFormaPagamento(formaPagamentoId) {
      this.formaPagamentoIdSelected = formaPagamentoId || null
      this.showCreateEditFormaPagamentoModal = true
    },

    closeCreateEditFormaPagamentoModal(loadListFormasPagamentos) {
      if (loadListFormasPagamentos) {
        this.getFormasPagamentos()
      }
      this.showCreateEditFormaPagamentoModal = false
    },
  },

  mounted() {
    this.getFormasPagamentos()
  },
}
</script>
