<template>
  <Layout>
    <div class="row">
      <div class="col-sm-6">
        <PageHeader :title="title" />
      </div>
      <div class="col-sm-6">
        <div style="text-align: right">
          <router-link
            v-if="getModuleUserByCode('2.01.002')"
            to="/financeiro/create-lancamento"
            class="btn btn-primary ml-auto"
            title="Novo Lançamento"
          >
            <i class="ri-add-fill align-middle"></i>Novo Lançamento
          </router-link>
        </div>
      </div>
    </div>
    <br />
    <LoadingData v-if="loadData" />
    <FiltroLancamentos />
    <div class="card-body"></div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import FiltroLancamentos from '@/components/financeiro/lancamentos/FiltroLancamentos.vue'

export default {
  name: 'Lancamentos',

  components: {
    Layout,
    PageHeader,
    LoadingData,
    FiltroLancamentos,
  },

  data() {
    return {
      title: 'Lançamentos',
      loadData: false,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },
}
</script>
