<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditTransferenciaModal
      v-if="showCreateEditTransferenciaModal"
      @closeModal="closeCreateEditTransferenciaModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Transferências</h5>
        <div v-if="getModuleUserByCode('2.07.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Nova transferência"
            @click="createEditTransferencia()"
          >
            <i class="ri-add-fill align-middle"></i>Nova Transferência
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListTransferencias :transferencias="transferencias" />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditTransferenciaModal from '@/components/financeiro/transferencia/CreateEditTransferenciaModal.vue'
import ListTransferencias from '@/components/financeiro/transferencia/ListTransferencias.vue'

export default {
  name: 'Transferencia',

  components: {
    Layout,
    PageHeader,
    CreateEditTransferenciaModal,
    LoadingData,
    ListTransferencias,
  },

  data() {
    return {
      title: 'Transferência',
      items: [
        {
          text: 'Transferencia',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditTransferenciaModal: false,
      transferencias: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getTransferencias() {
      try {
        this.transferencias = await this.$store.dispatch('getTransferencias')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditTransferencia() {
      this.showCreateEditTransferenciaModal = true
    },

    closeCreateEditTransferenciaModal(loadListTransferencias) {
      if (loadListTransferencias) {
        this.getTransferencias()
      }
      this.showCreateEditTransferenciaModal = false
    },
  },

  mounted() {
    this.getTransferencias()
  },
}
</script>
