<template>
  <b-modal
    id="modalCreateEditTransferencia"
    size="xl"
    title="Transferir entre contas"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Motivo: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.transferencia.motivo.$error }"
            placeholder="Motivo da transferência"
            v-model="$v.transferencia.motivo.$model"
          />
          <span
            v-if="!$v.transferencia.motivo.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
          <span
            v-if="!$v.transferencia.motivo.minLength"
            class="invalid-feedback"
          >
            A descrição deve possuir pelo menos 3 caracteres
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Conta Origem:</label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.transferencia.conta_id_origem.$error }"
            v-model="$v.transferencia.conta_id_origem.$model"
          >
            <option value="">Selecione uma conta</option>
            <option
              v-for="conta in contas"
              :key="conta.id"
              :value="conta.id"
              :title="`${conta.descricao}`"
            >
              {{ conta.descricao }}
            </option>
          </select>
          <span
            v-if="!$v.transferencia.conta_id_origem.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-3">
          <label>Data: </label>
          <input
            type="date"
            class="form-control"
            placeholder="0000-0"
            :class="{ 'is-invalid': $v.transferencia.data.$error }"
            v-model="$v.transferencia.data.$model"
          />
          <span v-if="!$v.transferencia.data.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-3">
          <label>Valor: <span class="text-danger">*</span></label>
          <div class="input-group">
            <span
              class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
            >
              <span class="input-group-text">R$</span></span
            >
            <input
              type="number"
              class="form-control"
              step="0.01"
              :class="{ 'is-invalid': $v.transferencia.valor.$error }"
              v-model.number="$v.transferencia.valor.$model"
              placeholder="000,00"
              @blur="formatarValor"
            />
          </div>
          <span
            v-if="!$v.transferencia.valor.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Conta Destino:</label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.transferencia.conta_id_destino.$error }"
            v-model="$v.transferencia.conta_id_destino.$model"
          >
            <option value="">Selecione uma conta</option>
            <option
              v-for="conta in contas"
              :key="conta.id"
              :value="conta.id"
              :title="`${conta.descricao}`"
            >
              {{ conta.descricao }}
            </option>
          </select>
          <span
            v-if="!$v.transferencia.conta_id_destino.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          title="Transferir entre contas"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>Transferir</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditTransferenciaModal',

  data() {
    return {
      transferencia: {
        motivo: '',
        data: '',
        valor: '',
        conta_id_origem: '',
        conta_id_destino: '',
      },
      contas: '',
      disableButton: false,
    }
  },

  methods: {
    async formatarValor() {
      const novoValor = this.transferencia.valor.toFixed(2)
      this.transferencia.valor = novoValor
    },

    async getContas() {
      try {
        this.contas = await this.$store.dispatch('getContas')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (
            this.transferencia.conta_id_origem ===
            this.transferencia.conta_id_destino
          ) {
            const alerta = 'As contas de origem e destino devem ser diferentes'
            this.$root.$bvToast.toast(alerta, {
              title: 'Atenção!',
              variant: 'danger',
              solid: true,
            })
            this.disableButton = false
          } else {
            response = await this.$store.dispatch(
              'saveTransferencia',
              this.transferencia,
            )
            this.disableButton = false
            this.$root.$bvToast.toast(response.message, {
              title: 'Sucesso!',
              variant: 'success',
              solid: true,
            })
            this.$emit('closeModal', true)
          }
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    transferencia: {
      motivo: {
        required,
        minLength: minLength(3),
      },
      data: {
        required,
      },
      valor: {
        required,
      },
      conta_id_origem: {
        required,
      },
      conta_id_destino: {
        required,
      },
    },
  },

  mounted() {
    this.getContas()
    this.$bvModal.show('modalCreateEditTransferencia')
  },
}
</script>
