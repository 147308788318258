<template>
  <b-modal
    id="modalLancarContrato"
    size="xl"
    title="Lançamento de Contratos"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save" ref="save">
      <div class="form-row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Titulo: <span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': $v.lancamento.titulo.$error }"
              placeholder="Descrição do lancamento"
              v-model="$v.lancamento.titulo.$model"
            />
            <span
              v-if="!$v.lancamento.titulo.required"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Vencimento: <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control"
                placeholder="Date de vencimento"
                :class="{
                  'is-invalid': $v.lancamento.data_vencimento.$error,
                }"
                v-model="$v.lancamento.data_vencimento.$model"
              />
              <span
                v-if="!$v.lancamento.data_vencimento.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
          </div>
          <div class="form-row">
            <div v-if="lancamento.tipo == 3" class="form-group col-sm-6">
              <label
                >Data do pagamento: <span class="text-danger">*</span></label
              >
              <input
                type="date"
                class="form-control"
                placeholder="Date de pagamento"
                v-model="lancamento.data_pagamento"
              />
            </div>
          </div>
          <div class="form-group">
            <p>Tipo: <span class="text-danger">*</span></p>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                id="contaAReceber"
                value="1"
                v-model="lancamento.tipo"
              />
              <label class="form-check-label" for="contaAReceber">
                A Receber
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                id="entrada"
                value="3"
                v-model="lancamento.tipo"
              />
              <label class="form-check-label" for="entrada"> Entrada </label>
            </div>
          </div>
          <div class="form-group">
            <label>Subcategoria: <span class="text-danger">*</span></label>
            <select
              class="form-control"
              :class="{
                'is-invalid': $v.lancamento.subcategoria_contas_id.$error,
              }"
              v-model="$v.lancamento.subcategoria_contas_id.$model"
            >
              <option value="">Selecione uma subcategoria</option>
              <option
                v-for="sub in subcategorias"
                :key="sub.id"
                :value="sub.id"
                :title="`${sub.descricao}`"
              >
                {{ sub.descricao }}
              </option>
            </select>
            <span
              v-if="!$v.lancamento.subcategoria_contas_id.required"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
          </div>
          <div class="form-group">
            <label>Conta: <span class="text-danger">*</span></label>
            <select
              class="form-control"
              :class="{ 'is-invalid': $v.lancamento.conta_id.$error }"
              v-model="$v.lancamento.conta_id.$model"
            >
              <option value="">Selecione uma conta</option>
              <option
                v-for="conta in contas"
                :key="conta.id"
                :value="conta.id"
                :title="`${conta.descricao}`"
              >
                {{ conta.descricao }}
              </option>
            </select>
            <span
              v-if="!$v.lancamento.conta_id.required"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
          </div>
          <div class="form-group">
            <label>Filial: <span class="text-danger">*</span></label>
            <select
              class="form-control"
              :class="{ 'is-invalid': $v.lancamento.unidade_id.$error }"
              v-model="$v.lancamento.unidade_id.$model"
            >
              <option value="">Selecione uma filial</option>
              <option
                v-for="unidade in unidades"
                :key="unidade.id"
                :value="unidade.id"
                :title="`${unidade.descricao}`"
              >
                {{ unidade.descricao }}
              </option>
            </select>
            <span
              v-if="!$v.lancamento.unidade_id.required"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
          </div>
        </div>

        <!-- 2ª coluna -->
        <div class="col-sm-6">
          <div class="form-group">
            <label>Valor: <span class="text-danger">*</span></label>
            <div class="input-group">
              <span
                class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
              >
                <span class="input-group-text">R$</span></span
              >
              <input
                type="number"
                class="form-control"
                step="0.01"
                :class="{ 'is-invalid': $v.lancamento.valor_contrato.$error }"
                v-model.number="$v.lancamento.valor_contrato.$model"
                placeholder="000,00"
                readonly
              />
            </div>
            <span
              v-if="!$v.lancamento.valor_contrato.required"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
          </div>
          <div v-if="lancamento.tipo == 3" class="form-row">
            <div class="form-group col-sm-6">
              <label
                >Condição de Pagamento:
                <span class="text-danger">*</span></label
              >
              <input
                type="number"
                class="form-control"
                v-model.number="lancamento.parcelas_pagamento"
                placeholder="00"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label
                >Forma de Pagamento: <span class="text-danger">*</span></label
              >
              <select
                class="form-control"
                :class="{
                  'is-invalid': $v.lancamento.forma_pagamento_id.$error,
                }"
                v-model="$v.lancamento.forma_pagamento_id.$model"
              >
                <option value="">Selecione uma forma de pagamento</option>
                <option
                  v-for="forma in formasPagamentos"
                  :key="forma.id"
                  :value="forma.id"
                  :title="`${forma.descricao}`"
                >
                  {{ forma.descricao }}
                </option>
              </select>
              <span
                v-if="!$v.lancamento.forma_pagamento_id.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-sm-6">
              <label>Nº do documento: </label>
              <input
                type="text"
                class="form-control"
                placeholder="Número do documento"
                v-model="lancamento.num_documento"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Nota Fiscal: </label>
              <input
                type="text"
                class="form-control"
                placeholder="Número do documento"
                v-model="lancamento.nota_fiscal"
              />
            </div>
            <div class="form-group col-sm-6">
              <label>Data de emissão da NF: </label>
              <input
                type="date"
                class="form-control"
                placeholder="Data de emissão"
                v-model="lancamento.data_nota_fiscal"
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="submit"
          class="btn btn-primary"
          title="Lançar"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>Lançar</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'LancarContratoModal',
  props: {
    contratoId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      lancamento: {
        titulo: '',
        data_vencimento_parcela: '',
        data_pagamento: '',
        tipo: 1,
        origem: '1',
        valor_contrato: '',
        num_documento: '',
        nota_fiscal: '',
        data_nota_fiscal: '',
        forma_pagamento_id: '',
        subcategoria_contas_id: '',
        unidade_id: '',
        conta_id: '',
        name: '',
        parcelas_pagamento: '',
      },
      num_replicar: '',
      tipo_replicar: 1,
      contas: null,
      formasPagamentos: null,
      subcategorias: null,
      unidades: null,
      parcelas: [],
      disableButton: false,
    }
  },

  methods: {
    // FORMATAR VALOR TOTAL E CAMPOS DE DINHEIRO
    async formatarValor(valorVar, campo) {
      this.$set(this.lancamento, campo, valorVar.toFixed(2))

      // calculo do valor total
      this.lancamento.valor_total = this.lancamento.valor_contrato
    },

    async getContrato() {
      try {
        this.lancamento = await this.$store.dispatch(
          'getContratoPreLancamento',
          this.contratoId,
        )
        this.lancamento.titulo = `${await this.lancamento.name} - ${
          this.lancamento.titulo
        }`
        this.lancamento.data_vencimento = await this.lancamento
          .data_vencimento_parcela
        this.$bvModal.show('modalLancarContrato')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getParcelasContrato() {
      try {
        this.parcelasAux = await this.$store.dispatch(
          'getParcelasPreLancamento',
          this.contratoId,
        )
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.parcelasAux.length; i++) {
          this.parcelas.push({
            tituloParcela: `${this.lancamento.titulo} ${i + 1}/${
              this.parcelas_pagamento
            }`,
            valor: this.parcelasAux[i].valor,
            vencimento: this.parcelasAux[i].vencimento,
            competenciaParcela: this.parcelasAux[i].competencia,
          })
        }
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getUnidades() {
      try {
        this.unidades = await this.$store.dispatch('getUnidades')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getFormasPagamentos() {
      try {
        this.formasPagamentos = await this.$store.dispatch(
          'getFormasPagamentos',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getSubcategorias() {
      try {
        this.subcategorias = await this.$store.dispatch(
          'getSubcategoriasContas',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getContas() {
      try {
        this.contas = await this.$store.dispatch('getContas')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          response = await this.$store.dispatch('saveLancamento', {
            id: this.lancamentoId,
            lancamento: this.lancamento,
            parcelas: this.parcelas,
            replicar: this.replicar,
            rateio: this.rateio,
          })
          this.$refs.saveLancamento.reset()
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
          })
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    lancamento: {
      titulo: {
        required,
      },
      data_vencimento: {
        required,
      },
      valor_contrato: {
        required,
      },
      forma_pagamento_id: {
        required,
      },
      subcategoria_contas_id: {
        required,
      },
      conta_id: {
        required,
      },
      unidade_id: {
        required,
      },
    },
  },

  async mounted() {
    this.getContas()
    this.getSubcategorias()
    this.getFormasPagamentos()
    this.getUnidades()
    if (this.contratoId) {
      await this.getContrato()
      this.lancamento.tipo = 1
      this.lancamento.unidade_id = ''
      this.lancamento.conta_id = ''
      this.lancamento.subcategoria_contas_id = ''
    }
  },
}
</script>
