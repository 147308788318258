<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditCentroCustoModal
      v-if="showCreateEditCentroCustoModal"
      :centroCustoId="centroCustoIdSelected"
      @closeModal="closeCreateEditCentroCustoModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Centro de Custo</h5>
        <div v-if="getModuleUserByCode('2.06.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Centro de Custo"
            @click="createEditCentroCusto()"
          >
            <i class="ri-add-fill align-middle"></i>Novo Centro de Custo
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListCentrosCustos
          :centrosCustos="centrosCustos"
          @editCentroCusto="createEditCentroCusto"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditCentroCustoModal from '@/components/financeiro/centroCusto/CreateEditCentroCustoModal.vue'
import ListCentrosCustos from '@/components/financeiro/centroCusto/ListCentrosCustos.vue'

export default {
  name: 'CentroCusto',

  components: {
    Layout,
    PageHeader,
    CreateEditCentroCustoModal,
    LoadingData,
    ListCentrosCustos,
  },

  data() {
    return {
      title: 'Centro de Custo',
      items: [
        {
          text: 'Centro de Custo',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditCentroCustoModal: false,
      centrosCustos: null,
      centroCustoIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getCentrosCustos() {
      try {
        this.centrosCustos = await this.$store.dispatch('getCentrosCustos')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditCentroCusto(centroCustoId) {
      this.centroCustoIdSelected = centroCustoId || null
      this.showCreateEditCentroCustoModal = true
    },

    closeCreateEditCentroCustoModal(loadListCentrosCustos) {
      if (loadListCentrosCustos) {
        this.getCentrosCustos()
      }
      this.showCreateEditCentroCustoModal = false
    },
  },

  mounted() {
    this.getCentrosCustos()
  },
}
</script>
