<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditCategoriaContaModal
      v-if="showCreateEditCategoriaContaModal"
      :categoriaContaId="categoriaContaIdSelected"
      @closeModal="closeCreateEditCategoriaContaModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Categorias de Contas</h5>
        <div v-if="getModuleUserByCode('2.02.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Nova Categoria de conta"
            @click="createEditCategoriaConta()"
          >
            <i class="ri-add-fill align-middle"></i>Nova Categoria
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListCategoriasContas
          :categorias="categorias"
          @editCategoriaConta="createEditCategoriaConta"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditCategoriaContaModal from '@/components/financeiro/categoriasContas/CreateEditCategoriaContaModal.vue'
import ListCategoriasContas from '@/components/financeiro/categoriasContas/ListCategoriasContas.vue'

export default {
  name: 'CategoriasContas',

  components: {
    Layout,
    PageHeader,
    CreateEditCategoriaContaModal,
    LoadingData,
    ListCategoriasContas,
  },

  data() {
    return {
      title: 'Categorias de Contas',
      items: [
        {
          text: 'Categorias',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditCategoriaContaModal: false,
      categorias: null,
      categoriaContaIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getCategoriasContas() {
      try {
        this.categorias = await this.$store.dispatch('getCategoriasContas')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditCategoriaConta(categoriaContaId) {
      this.categoriaContaIdSelected = categoriaContaId || null
      this.showCreateEditCategoriaContaModal = true
    },

    closeCreateEditCategoriaContaModal(loadListCategoriasContas) {
      if (loadListCategoriasContas) {
        this.getCategoriasContas()
      }
      this.showCreateEditCategoriaContaModal = false
    },
  },

  mounted() {
    this.getCategoriasContas()
  },
}
</script>
